.content-block {
    padding-bottom: 2rem;
    justify-Content: "center" !important;
}

.price-block {
    padding-bottom: 2rem;
    height: 8em;
    justify-Content: "center" !important;
}


.product-name {
    font-style: normal !important;
    font-weight: 400 !important;
}

.product-description {
    line-height: 120% !important;
    font-size: 1.125em !important;
    font-weight: 600 !important;
}


.currency-dropdown-container {
    display: flex !important;
    align-items: center !important;
    gap: "0.1em";

    .currency-dropdown {
        .MuiInputBase-input {
            padding-right: 0.5em !important;
        }

        .MuiTypography-root {
            font-size: 1.2em !important;
            font-weight: 600 !important;
        }

        .MuiSvgIcon-root {
            height: 1.0625em !important;
            width: 1.0625em !important;
        }
    }

}


.contact-selector-mobile .MuiTypography-h1 {
    font-size: calc(24px + 2vw) !important;
    letter-spacing: -1.5px !important;
    line-height: 1.02 !important;
}

.MuiTypography-h2 {
    font-size: calc(24px + 1vw) !important;
    line-height: 1.01 !important;
}

/* Media query for smaller screens */
@media (max-width: 600px) {

    .contact-selector-mobile .MuiTypography-h1,
    .MuiTypography-h2 {
        font-size: 2.25rem !important;
        /* Minimum font size */
    }
}

/* Media query for larger screens */
@media (min-width: 1920px) {
    .contact-selector-mobile .MuiTypography-h1 {
        font-size: 4rem !important;
        /* Maximum font size */
    }

    .MuiTypography-h2 {
        font-size: 3rem !important;
        /* Maximum font size */
    }
}

@media (max-width: 405px) {

    .MuiTypography-h2 {
        font-size: 1.5rem !important;
        /* Minimum font size */
    }
}

.buttonStyle {
    width: 100%;
    padding: 1.5em !important;
    border-radius: 8px !important;
    font-size: 1em !important;
    font-weight: 600 !important;
}


.credit-card {
    line-height: 215% !important;
    margin-bottom: 0.3em !important;
}


.priceAmount {
    line-height: 1.75rem !important;
    font-size: 72px !important;
    font-weight: 400 !important;
    padding-bottom: 0.2em !important;
    padding-top: 0.2em !important;
}

.card-chip {
    margin-bottom: 1em;
}

.chip-padding {
    padding: 1.1em;
}

.priceAmount::before {
    content: '$';
    font-size: 0.4em;
    vertical-align: top;
    position: relative;
    top: -0.5em;
}

.priceAmount.eur::before {
    content: '€';
}

.priceAmount.gbp::before {
    content: '£';
}

.priceAmount.brl::before {
    content: 'R$';
}

.priceAmount.inr::before {
    content: '₹';
}

.your-price-label {
    padding-bottom: 0.6em;
}

.shadowed-element {
    border: 1px solid #DDDDDD;
    box-shadow: none !important;
    /*border: 1px solid #ccc;*/
    /* Add a border if desired */
    /*box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1);*/
    /* Add a shadow */
    padding: 2em !important;
    border-radius: 24px !important;
}

.default-card-container .text-container {
    height: 5em;
    overflow: visible;
}

.with-legacy-card-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 2em;
}

.with-legacy-card-container .text-container {
    height: 6em;
}

.default-card-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2em;
}

@media(max-width:1070px) {
    .all-container {
        display: block;
    }

    .with-legacy-card-container {
        grid-template-columns: repeat(3, 1fr);
    }

    .with-legacy-card-container div:nth-child(4) {
        grid-column: 1 / span 3;
        /* Spans across all three columns */
    }
}

@media (min-width: 1440px) {
    .shadowed-element {
        padding: 3em !important;
    }
}

@media (max-width: 990px) {
    .with-legacy-card-container .text-container {
        height: 2em;
    }

    .shadowed-element {
        /* Adjust styles for smaller screens */
        height: auto;
        min-height: auto;
        margin-right: 0;
        padding: 2em;
        /* Other responsive styles for the card */
    }

    .chip-padding {
        padding: 0;
    }


    .default-card-container .text-container {
        height: 4em;
        overflow: visible;
    }

    .with-legacy-card-container {
        display: block;
    }

    .default-card-container {
        display: block;
    }
}


@media(max-width:1200px) {
    .text-container {
        height: 3em;
    }
}