.icon-container {
    display: flex !important;
    flex-direction: column !important;
}

.icon-text-box {
    display: flex !important;
    align-items: center !important;
    /* Align the icon and text on the same line */
}

.fundamental-features {
    display: flex;
    justify-content: space-between;
    height: 1.2em;
    align-items: center,
}

.contact-selector-in-card {
    align-items: center !important;

    .MuiInput-input {
        padding-right: 0 !important;
    }

    .MuiTypography-root {
        font-size: 1.2em !important;
        font-weight: 600 !important;
    }

    .MuiSvgIcon-root {
        height: 1.0625em !important;
        width: 1.0625em !important;
    }
}

.feature-description {
    margin-left: 1.4rem !important;
    margin-bottom: 0.5rem !important;
    margin-right: 1.4rem !important;
}

.small-icon {
    margin-right: 8px !important;
    /* Space between the icon and text */
}

.category {
    text-transform: 'none' !important;
    padding-bottom: 0.5em !important;
    display: inline-block !important;
}

.plan-limits-container {
    padding-bottom: 1em;
}

.plan-feature {
    padding-bottom: 0.5em !important;
}