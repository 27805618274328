.divider {
    height: 1px;
    /* Divider height */
    background-color: #F3F3F3;
    /* Background color */
    margin: 8px 0;
    /* Vertical margin */
    padding-left: 0 !important;
    /* Left padding */
}

.feature-description-2 {
    margin-left: 1.2rem !important;
    width: 90%;
}


.icon-container {
    display: flex !important;
    flex-direction: column !important;
    width: 100% !important;
}


.billing-underline_label {
    cursor: pointer;
    border-bottom: 1px dashed #9B9B9B;
}