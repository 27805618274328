.with-legacy-card-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 2em;
}

.buttonStripeIntegration {
    padding: 1em !important;
    width: 100% !important;
    font-size: 1em !important;
    border-radius: 8px !important;
}

.default-card-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2em;
}


.centered-button-billing {
    justify-content: center !important;
    display: flex !important;
    align-items: center !important;
    padding-top: 2.05rem !important;
    padding-bottom: 0.05rem !important;

}

.centered-button-billing-bottom {
    justify-content: center !important;
    display: flex !important;
    align-items: center !important;
    padding-bottom: 0.05rem !important;
}

.hide-table {
    font-size: 1em !important;
}

.comparing-all-plan-features {
    padding-top: 2rem !important;
}

.comparing-all-plan-features-text {
    font-weight: 400 !important;
}

.button-spacing {
    display: flex !important;
    align-items: center !important;
    /* or any desired width */
}

.icon-padding-right {
    margin-right: 0.5em !important;
}

.white-button {
    background-color: white !important;
    color: black !important;
}


@media(max-width:1070px) {
    .all-container {
        display: block;
    }

    .with-legacy-card-container {
        grid-template-columns: repeat(3, 1fr);
    }

    .with-legacy-card-container div:nth-child(4) {
        grid-column: 1 / span 3;
        /* Spans across all three columns */
    }
}


@media (max-width: 990px) {
    .with-legacy-card-container {
        display: block;
    }

    .default-card-container {
        display: block;
    }
}