.divider {
    height: 1px;
    /* Divider height */
    background-color: #F3F3F3;
    /* Background color */
    margin: 8px 0;
    /* Vertical margin */
    padding-left: 0 !important;
    /* Left padding */
}

.feature-table-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: 100%;
    padding-top: 1rem;
}

.starting-price-text {
    font-size: 1.375em !important;
}

@media (max-width: 800px) {
    .feature-table-container {
        display: block !important;
        width: 100% !important;
    }
}

@media (max-width: 990px) {
    .with-legacy-card-container {
        display: block;
    }

    .default-card-container {
        display: block;
    }

}

.starting-price {
    padding-bottom: 1rem !important;
}