.plan-header {
    font-style: normal !important;
    font-weight: 300 !important;
    line-height: 103% !important;
    margin-top: 1em !important;
}

@media(max-width:990px) {
    .group {
        display: block !important;
    }
}

.group {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1em;
    margin-top: 0.5em;
}