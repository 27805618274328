.custom-link {
    margin-top: 1em !important;
}

.current-plan {
    text-align: right !important;
}

.custom-link:hover {
    cursor: pointer;
    /* Change cursor to a pointer on hover */
}

@media (max-width: 990px) {
    .current-plan {
        text-align: left !important;
    }
}