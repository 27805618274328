.contact-selector {
    display: flex;
    align-items: center;

    .contact-dropdown {
        background-color: white !important;
    }
}

.contact-dropdown .MuiPaper-root {
    max-height: 600px !important;
}

.contact-selector-mobile {
    display: flex !important;
    align-items: center !important;
    margin-top: 0.5em !important;
    justify-content: center !important;
    padding-bottom: 4em !important;

    .mobile-size {
        margin-right: 2rem;
    }


    .contact-dropdown {
        background-color: white !important;
        font-size: 1.5em !important;
        border-radius: 1rem !important;

        .MuiTypography-root {
            margin-right: 0.75em !important;
        }

        .MuiInputBase-input {
            padding: 1.5rem !important;
        }

        .MuiSvgIcon-root {
            width: 1.5em;
            height: 1.5em;
        }
    }

}

@media(max-width:990px) {

    .contact-selector-mobile {
        flex-direction: column !important;
        padding-bottom: 2em !important;

        .dropdown-component-v2 {
            align-self: center !important;
        }

        .mobile-size {
            padding-bottom: 1em !important;
        }
    }
}

@media(max-width:400px) {
    .contact-selector {
        display: block;
    }
}